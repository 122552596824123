* {
    box-sizing: border-box;

    font-family: "Syncopate", sans-serif;
    font-weight: 700;
    font-style: normal;
}

html,
body,
#root,
.page_box {
    width: 100%;
    height: 100%;
}

.regular {
    font-family: "Vast Shadow", serif;
    font-weight: 400;
    font-style: normal;
}

.bold {
    font-family: "Vast Shadow", serif;
    font-weight: 400;
    font-style: normal;
}

::-webkit-scrollbar {
    /* 滚动条整体部分 */
    width: 6px;
    margin-right: 0px;
}

::-webkit-scrollbar:horizontal {
    height: 4px;
    margin-bottom: 4px;
}

::-webkit-scrollbar-thumb {
    /* 滑块 */
    width: 2px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:hover {
    /* 鼠标移入滑块 */
    background: #909090;
}

@keyframes scaleUpDown {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    to {
        opacity: 1;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.header-box {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding-left: 0.48rem;
    padding-top: 0.42rem;
    z-index: 100;
    display: flex;
    align-items: center;
    @media (max-width: 900px) {
        padding-left: 0;
    }
    .logo_box {
        display: flex;
        align-items: center;
        @media (max-width: 900px) {
            display: none;
        }
        .logo {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 0.08rem;
            img {
                width: 100%;
                border-radius: 0.08rem;
            }
        }
        .text {
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-size: 0.24rem;
            color: #ffffff;
            line-height: 0.35rem;
            margin-left: 0.14rem;
        }
    }
    .link_box {
        display: flex;
        margin-left: 1.1rem;
        @media (max-width: 900px) {
            margin: 0;
            justify-content: space-around;
            width: 100%;
        }
        .text {
            padding: 0 0.46rem;
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            font-size: 0.24rem;
            color: #9b9b9b;
            line-height: 0.35rem;
            @media (max-width: 900px) {
                padding: 0;
                width: 33%;
                text-align: center;
            }
        }
        .ml {
            border-left: 0.02rem solid rgba(255, 255, 255, 0.2);
            border-right: 0.02rem solid rgba(255, 255, 255, 0.2);
        }
        .active {
            color: #ff8d0a;
            font-weight: 600;
        }
    }
}
.page {
    height: 100%;
    width: 100%;
    background: url("../images/bg.png") no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    @media (max-width: 900px) {
        background: url("../images/bg1.png") no-repeat;
        background-size: cover;
        background-position: center center;
        align-items: start;
    }
    .box {
        margin-left: 1.52rem;
        @media (max-width: 900px) {
            margin-top: 2rem;
            margin-left: 0.67rem;
            width: 100%;
        }

        .title {
            font-family: "Poppins", sans-serif;
            font-size: 1.08rem;
            color: #ffffff;
            line-height: 1.52rem;
        }
        .tip_box {
            width: 4.02rem;
            margin-top: 0.2rem;
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-size: 0.28rem;
            color: #ffffff;
            line-height: 0.4rem;
            opacity: 0.7;
        }
        .apple_store {
            margin-top: 30%;
            width: 3.8rem;
            img {
                width: 100%;
            }
            @media (max-width: 900px) {
                margin-top: 0.25rem;
                margin-left: auto;
                margin-right: 0.77rem;
            }
        }
    }
}

.pact-page {
    width: 100%;
    height: 100%;
    background: url("../images/box_bg.png") no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    display: flex;
    align-items: center;
    @media (max-width: 900px) {
        background: url("../images/box_bg1.png") no-repeat;
    background-size: 100% 100%;
    background-position: center center;
    justify-content: center;
    padding: 0 .6rem;
    }

    .box {
        width: 76%;
        height: 87%;
        margin-left: 6%;
        padding: 0.2rem 1rem;
        @media (max-width: 900px) {
         
          margin:0;
          width: 100%;
          padding: 0 .4rem;
         
        }
        .title {
            font-family: "Anton", sans-serif;
            font-weight: 400;
            font-size: 0.48rem;
            color: #ffffff;
            line-height: 0.72rem;
            margin-bottom: 0.2rem;
            @media (max-width: 900px) {
                font-size: 0.54rem;
                line-height: 0.82rem;
               }
        }
        .pact-cont {
            height: 85%;
            overflow: auto;
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }
            @media (max-width: 900px) {
               height: 88%;
               
              }
            p,
            li {
                font-family: "Roboto", sans-serif;
                padding-bottom: 0.3rem;
                font-weight: 400;
                font-size: 0.26rem;
                line-height: 0.3rem;
                color: #fff;
                @media (max-width: 900px) {
                    font-size: 0.24rem;
                    line-height: 0.33rem;
                }
            }

            h2 {
                font-family: "Anton", sans-serif;
                font-size: 0.28rem;
                line-height: 0.42rem;
                font-weight: 700;
                color: #ffffff;
                @extend .bold;
                margin-bottom: 0.14rem;
                margin-top: 0.26rem;
                border-radius: 0.12rem;
                @media (max-width: 900px) {
                    font-size: 0.32rem;
                    line-height: 0.33rem;
                }
            }

            strong {
                font-weight: bold;
            }
        }
    }
}
